/** Natives **/
import React, { Component } from 'react';
/** SCSS **/
import './Main.scss';
/* Plugins */
import Papa from 'papaparse';
/* Services */
import StringService from '../../services/StringService';
//Class
const stringService  = new StringService();

class Main extends Component {
  constructor() {
    super();
    this.state = {
      productList   : undefined,
      variablesList : undefined,
      textImported  : undefined,
      fieldName     : "",
      arrayProducts : [],
      arrayTextCompleted : [],
      isLoaded      : false,
      info_text     : ""
    };
    this._importProductCSV   = this._importProductCSV.bind(this);
    this._importVariablesCSV = this._importVariablesCSV.bind(this);
    this._formatVariables    = this._formatVariables.bind(this);
  }

  /*-------------------------------------------------*/
  /*----------------- Fields values -----------------*/
  /*-------------------------------------------------*/

  /* On change product file */
  _handleChangeProduct = event => {
    let fileType = 'csv';
    let extension = event.target.files[0].name.split('.').pop().toLowerCase()
    if(extension !== fileType){
      return this.setState({ isLoaded: false, info_text : 'Le fichier des produits doit être au format .csv'});
    }else{
      return this.setState({
        productList : event.target.files[0],
        info_text   : 'Fichier des produits importé.',
        isLoaded    : true
      });
    }
  };

  /* On change variables file */
  _handleChangeVariables = event => {
    let fileType = 'csv';
    let extension = event.target.files[0].name.split('.').pop().toLowerCase()
    if(extension !== fileType){
      return this.setState({ isLoaded: false, info_text : 'Le fichier des variables doit être au format .csv'});
    }else{
      return this.setState({
        variablesList : event.target.files[0],
        info_text     : 'Fichier des variables importé.',
        isLoaded      : true
      });
    }
  };

  /* On change text file */
  _handleChangeText = event => {
    let that = this;
    let fileType = 'html';
    let extension = event.target.files[0].name.split('.').pop().toLowerCase()
    if(extension !== fileType){
      return this.setState({ isLoaded: false, info_text: 'Le fichier du texte doit être au format .html'});
    }else{
      let reader = new FileReader();
      reader.onload = function(e) {
        return that.setState({
          textImported : e.target.result,
          info_text    : 'Fichier du texte importé.',
          isLoaded     : true
        });
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  /* Select the name of the field of the text */
  _selectFieldName = (fieldName)  => this.setState({fieldName : fieldName.target.value});


  /*-------------------------------------------------*/
  /*------------------ Formatting -------------------*/
  /*-------------------------------------------------*/

  /* I/ Import the CSV and parse it */
  _importProductCSV = () => {
    const { productList } = this.state;

    if(productList !== undefined){
      Papa.parse(productList, {
        skipEmptyLines : true,
        header         : true,
        complete       : this._importVariablesCSV,
      });
    }else this.setState({info_text: 'Veuillez charger un fichier de produits'});
  };
  
  /*  II/ Import the CSV and parse it */
  _importVariablesCSV(result) {
    this.setState({arrayProducts : result.data})

    const { variablesList } = this.state;
    if(variablesList !== undefined){
      Papa.parse(variablesList, {
        skipEmptyLines : true,
        complete       : this._formatVariables
      });
    }else this.setState({info_text: 'Veuillez charger un fichier de variables'});
  };

  /*  III/ Format the variables array */
  _formatVariables(result){
    var variables = result.data;

    let tempVariables = [];
    if(variables){
      for(let i=0; i<variables.length; i++){
        let firstVar = variables[i][0].replace(/{/gi,'').replace(/}/gi,'')

        if(!tempVariables[firstVar]){
          tempVariables[firstVar] = {}; 
          tempVariables[firstVar].name = variables[i][0]; 
          tempVariables[firstVar].values = [];
        } 

        for(let y=0; y<variables[i].length;y++){
          if(variables[i][y]!== ""){
            if(y === 0){
              tempVariables[firstVar].values.push(firstVar)
            }else{
              tempVariables[firstVar].values.push(variables[i][y])
            } 
          }
        }
      }
    }
    if(this.state.textImported !== undefined) this._formatText(tempVariables);
    else this.setState({info_text: 'Veuillez charger un texte'});
  }
  
  /* IV/ Include variables in the text*/
  _formatText(variables){
    console.log('variables',variables)
    let txtImported = this.state.textImported;
    let arrayProducts = this.state.arrayProducts;
    let arrayTextCompleted = [];
    
    for(let i=0; i<arrayProducts.length; i++){
      arrayTextCompleted[i] = txtImported;

      const arrayKeys = Object.keys(arrayProducts[i]);
      let foundName        = arrayKeys.find((key) => key.toLowerCase().includes("name") || key.toLowerCase().includes("nom"));
      let foundCategory    = arrayKeys.find((key) => stringService.accentFold(key.toLowerCase()).includes("caracteristiques"));
      let foundSubCategory = arrayKeys.find((key) => stringService.accentFold(key.toLowerCase()).includes("sous-caracteristiques") || stringService.accentFold(key.toLowerCase()).includes("sous caracteristiques"));

      let name = "";
      if(foundName) name = arrayProducts[i][foundName]
      else name = "le produit";

      let category = "";
      if(foundCategory) category = arrayProducts[i][foundCategory]
      else category = "général";
      
      let subCategory = "";
      if(foundSubCategory) subCategory = arrayProducts[i][foundSubCategory]
      else subCategory = "général";

      Object.values(variables).forEach(variable => {
        let randomNumber = stringService.getRandomNumber(variable.values);
        arrayTextCompleted[i] = stringService.replaceAll(arrayTextCompleted[i], variable.name, variable.values[randomNumber], name, category, subCategory);
      })
    }
    this.setState({arrayTextCompleted: arrayTextCompleted})
    this._transposeText();
  }

  /* V/ Copy the differents generated texts to the product file */
  _transposeText(){
    let arrayProducts      = this.state.arrayProducts;
    let arrayTextCompleted = this.state.arrayTextCompleted;
    let fieldName          = this.state.fieldName;
    let info_text          = this.state.info_text;
    let error              = false;

    console.log('arrayProducts',arrayProducts)
    console.log('arrayTextCompleted',arrayTextCompleted)
    console.log('fieldName',fieldName)

    for(let i=0; i<arrayProducts.length; i++){
      // Check if the filed name exist in product data
      const arrayKeys = Object.keys(arrayProducts[i]);
      let foundKey = arrayKeys.find((key) => key.toLowerCase() === fieldName.toLowerCase());
      if(foundKey){
        error = false;
        arrayProducts[i][fieldName] = arrayTextCompleted[i];
      }else{ 
        info_text = "Le nom rentré n'existe pas dans la liste des produits."; 
        error = true;
      } 
    }

    if(error === false){
      info_text = "Le texte a été généré."
      let csv   = Papa.unparse(arrayProducts)
      var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      var csvURL  =  null;
      if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, 'produits-générés.csv'); 
      else  csvURL  = window.URL.createObjectURL(csvData);
      var tempLink  = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'produits-générés.csv');
      tempLink.click();
    }
    this.setState({ info_text: info_text});
  }


  /*-------------------------------------------------*/
  /*-------------------- Render ---------------------*/
  /*-------------------------------------------------*/

  render() {
    return (
      <div className="main-container">
        <div className="upload-buttons-row">

          {/* Product file (csv) */}
          <div className="upload-buttons-col">
            <div className="upload-buttons-col-title">Fichier produits</div>
            <input
              className = "button-file"
              type = "file"
              ref  = {input => {this.filesInput = input;}}
              name = "product"
              placeholder = {null}
              onChange = {this._handleChangeProduct}
            />
          </div>

          {/* Variables file (csv) */}
          <div className="upload-buttons-col">
            <div className="upload-buttons-col-title">Mots-clés</div>
            <input
              className   = "button-file"
              type        = "file"
              ref         = {input => {this.filesInput = input;}}
              name        = "variables"
              placeholder = {null}
              onChange    = {this._handleChangeVariables}
            />
          </div>

          {/* Text file (html) */}
          <div className="upload-buttons-col">
            <div className="upload-buttons-col-title">Texte</div>
            <input
              className = "button-file"
              type = "file"
              ref  = {input => {this.filesInput = input;}}
              name = "text"
              placeholder = {null}
              onChange = {this._handleChangeText}
            />
          </div>

          {/* Name of the field in the product file to replace */}
          <div className="upload-buttons-col">
            <div className="upload-buttons-col-title">Nom du champ</div>
            <input type="text" value={this.state.fieldName} onChange={this._selectFieldName}></input>
          </div>
        </div>

        {/* Generate the file */}
        <div className="main-container-body">
          <p className="main-container-body-text">{this.state.info_text}</p>
          <button className="main-container-body-generate" onClick={this._importProductCSV}>Générer le texte</button>
        </div>
      </div>      
    );
  }
}

export default Main;


  
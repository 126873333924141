class StringService {

    /* Get a random value from an interval of an array */
    getRandomNumber(array) {
        return Math.floor(Math.random() * (array.length));
    }

    /* Remove accents in a string */
    accentFold(inStr) {
        return inStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    /* Replace all occurences in a text */
    replaceAll(txt, search, replacement, name, category, subCategory) {
        console.log('search',search)
        return txt.split(search).join(replacement).split('{nom_produit}').join(name).split('{caractéristiques}').join(category).split('{sous-caractéristiques}').join(subCategory)
        // return txt.replace(search, replacement).replace('{nom_produit}',name).replace('{caractéristiques}',category).replace('{sous-caractéristiques}',subCategory);
    };
}

export default StringService;